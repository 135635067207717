import "./register.scss";
import { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [answer, setAnswer] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault(); // prevent page refresh
    // console.log(name, email, password);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/register`,
        {
          name,
          email,
          answer,
          password,
        }
      );
      if (res.data.success) {
        toast.success(res.data.message);
        navigate("/login");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <Layout title={"Register - NanduChef"}>
      <div className="register_container">
        <div className="register_wrapper">
          <p className="title">CREATE AN ACCOUNT</p>

          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            {/* <input type="text" placeholder="Last name" /> */}
            {/* <input type="text" placeholder="Username" /> */}
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="What is your favourite food?"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {/* <input type="password" placeholder="Confirm password" /> */}
            {/* <span>
              By creating an account, I consent to the processing of my personal
              data in accordance with the <b>PRIVACY POLICY</b>
            </span> */}
            <Link to="/login">Already have an Account? | Login</Link>
            <button type="submit">CREATE ACCOUNT</button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
