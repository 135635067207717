import toast from "react-hot-toast";
import useCategory from "../../hooks/useCategory";
import { Link, NavLink } from "react-router-dom";
import { useState, useRef } from "react";
import "./header.scss";
import logo from "../../Images/nanduchefRound.png";
import { useAuth } from "../../context/auth";
import {
  LocationOn,
  ArrowDropDown,
  Menu,
  Close,
  Person,
} from "@mui/icons-material";

import SearchForm from "../Form/SearchForm";

const Header = () => {
  const [menu, setMenu] = useState("menu");
  const header__nav = useRef(null);
  const [auth, setAuth] = useAuth();
  const categories = useCategory();

  const handleMenuBtn = () => {
    if (menu === "menu") {
      setMenu("x");
      header__nav.current.classList.add("active");
    } else {
      setMenu("menu");
      header__nav.current.classList.remove("active");
    }
  };
  const handleLinks = () => {
    header__nav.current.classList.remove("active");
    setMenu("menu");
  };

  const handleLogOut = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("LogOut Successfully");
  };

  return (
    <header className="page_header">
      <div className="header_content">
        <Link className="logo" to="/">
          <img src={logo} alt="nandu-chef" />
        </Link>
        <SearchForm />
        <div className="quick_links">
          <NavLink to="/findStore">
            <span className="store-link">Find Our Store</span>
            <LocationOn />
          </NavLink>
          {!auth.user ? (
            <>
              <li>
                <NavLink to="/login">LogIn|Register</NavLink>
              </li>
            </>
          ) : (
            <>
              <ul className="dropdown">
                <li>
                  <Person />
                  {auth?.user?.name}
                  <ArrowDropDown />
                </li>
                <ul className="dropdown-content">
                  {auth?.user?.isAdmin && (
                    <li>
                      <NavLink
                        // to={`/dashboard/${
                        //   auth?.user?.isAdmin ? "admin" : "user"
                        // }`}
                        to={`/dashboard/admin`}
                      >
                        Dashboard
                      </NavLink>
                    </li>
                  )}

                  <li>
                    <NavLink to="/login" onClick={handleLogOut}>
                      LogOut
                    </NavLink>
                  </li>
                </ul>
              </ul>
            </>
          )}

          <div className="menubtn" onClick={handleMenuBtn}>
            {menu === "menu" ? <Menu /> : <Close />}
          </div>
        </div>
      </div>
      <nav className="header__nav">
        <ul ref={header__nav} className="navbar">
          <li className="nav-items">
            <NavLink
              to="/"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              Home
            </NavLink>
          </li>
          {categories?.map((item, index) => {
            return (
              <li className="nav-items" key={index}>
                <NavLink
                  onClick={handleLinks}
                  to={`/category/${item.slug}`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  {item.name}
                </NavLink>
              </li>
            );
          })}
          <li className="nav-items">
            <NavLink
              to="/contact"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              Contact Us
            </NavLink>
          </li>
          <li className="nav-items">
            <NavLink
              to="/about"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              About Us
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
