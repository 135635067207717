import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout/Layout";
import axios from "axios";
import { useAuth } from "../context/auth";
import Products from "../Components/Products/Products";
import "./homepage.scss";
import Loading from "../Components/Loading/Loading";
import Slider from "../Components/Slider/Slider";
const HomePage = () => {
  const [auth, setAuth] = useAuth();

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadmore, setLoadMore] = useState(false);
  const [popularProducts, setPopularProducts] = useState([]);

  // Fetch popular products
  const getPopularProducts = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/products/popular-products`
      );
      setPopularProducts(data.popularProducts);
    } catch (error) {
      console.log(error);
    }
  };
  // get total count

  const getTotal = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/products/product-count`
      );
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  // load more products
  useEffect(() => {
    if (page === 1) return;
    loadMoreProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadMoreProducts = async () => {
    try {
      setLoadMore(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/products/product-list/${page}`
      );
      setLoadMore(false);
      setProducts([...products, ...data.products]);
    } catch (error) {
      console.log(error);
      setLoadMore(false);
    }
  };

  // get products

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/products/product-list/${page}`
      );
      setLoading(false);
      setProducts(data.products);
    } catch (error) {
      console.log(error);
    }
  };
  // get all category

  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/category/get-category`
      );
      setCategories(data.categories);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTotal();
    getAllCategory();
    getAllProducts();
    getPopularProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title={"NanduChef - All Products"}>
      <div className="homepage_container">
        <Slider />
        {loading ? (
          <Loading />
        ) : (
          <Products title="All Products" ProductItems={products} />
        )}
      </div>
      <div className="m-2 p-3 loadmorecontainer">
        {products && products.length < total && (
          <button
            className="btn loadmore"
            onClick={(e) => {
              e.preventDefault();
              setPage(page + 1);
            }}
          >
            {loadmore ? "Loading..." : "Load More"}
          </button>
        )}
      </div>
    </Layout>
  );
};

export default HomePage;
