import { ArrowRightOutlined, ArrowLeftOutlined } from "@mui/icons-material";
import "./slider.scss";
import { useState, useEffect } from "react";
// import styled from "styled-components";
import axios from "axios";
import Slide from "./Slide";

const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [slideWidth, setSlideWidth] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [slideItems, setSlideItems] = useState([]);

  const getSliderProducts = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/slider/get-slider-products`
      );
      console.log(data);
      setSlideItems(data.SliderProduct);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSliderProducts();
  }, []);

  // choose the screen size
  // const handleResize = () => {
  //     if (window.innerWidth <= 700) {
  //         // setIsMobile(true)
  //         setSlideWidth('100')
  //         console.log('mobile')
  //         console.log(slideWidth)
  //     } else {
  //         setSlideWidth('50')
  //         console.log('tablet')
  //         console.log(slideWidth)
  //     }
  // }

  const wrapperstyle = {
    transform: `translateX(${slideIndex * -slideWidth}vw)`,
  };
  const handleClick = (direction) => {
    if (direction === "left") {
      if (slideIndex === 0) {
        return;
      }

      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : slideItems.length - 1);
    } else {
      setSlideIndex(
        slideIndex < slideItems.length - (isMobile ? 1 : 2) ? slideIndex + 1 : 0
      );
    }
  };
  useEffect(() => {
    if (window.innerWidth <= 700) {
      setSlideWidth("100");
      setIsMobile(true);
    } else {
      setSlideWidth("50");
      setIsMobile(false);
    }

    // window.addEventListener("resize", handleResize)
    const interval = setInterval(() => {
      setSlideIndex(
        slideIndex < slideItems.length - (isMobile ? 1 : 2) ? slideIndex + 1 : 0
      );
    }, 4000);
    return () => clearInterval(interval);
  }, [slideItems.length, slideIndex, isMobile]);

  return (
    <>
      <div className="slider-container">
        <div className="arrow left" onClick={() => handleClick("left")}>
          <ArrowLeftOutlined />
        </div>
        <div className="wrapper" style={wrapperstyle}>
          {slideItems.map((item, index) => {
            return (
              <Slide
                key={index}
                id={item.id}
                img={`${process.env.REACT_APP_API}/api/v1/slider/slider-product-image/${item._id}`}
                title={item.name}
              />
            );
          })}
        </div>
        <div className="arrow right" onClick={() => handleClick("right")}>
          <ArrowRightOutlined />
        </div>
      </div>
    </>
  );
};

export default Slider;
