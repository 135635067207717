import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import AdminMenu from "../../Components/Layout/AdminMenu";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
// select imports
import { Select } from "antd";
const { Option } = Select;

const UpdateProducts = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [id, setId] = useState("");
  const auth = JSON.parse(localStorage.getItem("auth"));

  // get single product
  const getSingleProduct = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/products/get-product/${params.slug}`
      );
      setName(data.product.name);
      setId(data.product._id);
      setDescription(data.product.description);
      setCategory(data.product.category._id);
      console.log(data.product.category);
    } catch (error) {
      console.log(error);
    }
  };

  // Get all categories
  const getAllCategory = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/category/get-category`
      );
      if (res?.data.success) {
        setCategories(res.data.category);
      }
      // console.log(res.data.category);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getSingleProduct();
    getAllCategory();
  }, []);
  // const handleChange = () => {
  //   console.log("hello");
  // };
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      // if you dont want to use form data then wrap all input and select tag tags with in form tag and add handle create function on form onsubmit event
      const productData = new FormData();
      productData.append("name", name);
      productData.append("description", description);
      productData.append("category", category);
      image && productData.append("image", image);
      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/products/update-product/${id}`,
        productData,
        {
          headers: {
            authorization: `${auth.token}`,
          },
        }
      );
      if (data?.success) {
        toast.success(data.message);
        navigate("/dashboard/admin/products");
        setName("");
        setDescription("");
        setCategory("");
        setImage("");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleDelete = async (e) => {
    try {
      let answer = window.confirm(
        "Are you sure you want to delete this product?"
      );
      if (!answer) return;
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/products/delete-product/${id}`,

        {
          headers: {
            authorization: `${auth.token}`,
          },
        }
      );
      console.log(data);
      if (data?.success) {
        toast.success(data.message);
        navigate("/dashboard/admin/products");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <Layout title={"Create Products - NanduChef"}>
      <div className="adminDashboard">
        <AdminMenu />
        <div className="adminDashboard__content">
          <h1>Update Products</h1>
          <div className="product-inputs">
            <Select
              bordered={true}
              placeholder="Select Category"
              onChange={(value) => {
                setCategory(value);
              }}
              size="large"
              showSearch
              value={category}
            >
              {categories?.map((c) => {
                return (
                  <Option key={c._id} value={c._id}>
                    {c.name}
                  </Option>
                );
              })}
            </Select>
            <div className="mb-3 productupload">
              <label className="btn mb-2">
                {image ? image.name : "Upload Image"}
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={(e) => setImage(e.target.files[0])}
                  hidden
                />
              </label>
            </div>
            <div className="mb-2">
              {image ? (
                <div className="text-center image-preview">
                  <img src={URL.createObjectURL(image)} alt="product_image" />
                </div>
              ) : (
                <div className="text-center image-preview">
                  <img
                    src={`${process.env.REACT_APP_API}/api/v1/products/product-image/${id}`}
                    alt="product_image"
                  />
                </div>
              )}
            </div>
            <input
              type="text"
              value={name}
              className="form-control"
              placeholder="Write a name"
              onChange={(e) => setName(e.target.value)}
            />

            <input
              type="text"
              value={description}
              className="form-control"
              placeholder="Write Description"
              maxLength={360}
              onChange={(e) => setDescription(e.target.value)}
            />
            <button className="btn mb-2" onClick={handleUpdate}>
              Update Product
            </button>
            <button className="btn danger" onClick={handleDelete}>
              Delete Product
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UpdateProducts;
