import React from "react";
import Layout from "../../Components/Layout/Layout";

const Dashboard = () => {
  return (
    <>
      <Layout title={"Dashboard"}>
        <div>Dashboard</div>;
      </Layout>
    </>
  );
};

export default Dashboard;
