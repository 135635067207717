import { useState, useContext, useEffect, createContext } from "react";

import axios from "axios";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    token: "",
    user: null,
  });

  // default axios

  axios.defaults.headers.common["Authorization"] = auth?.token;

  useEffect(() => {
    const data = localStorage.getItem("auth");
    if (data) {
      const parsedData = JSON.parse(data);
      // setAuth({ ...auth, user: parsedData.user, token: parsedData.token });
      setAuth((prevAuth) => ({
        ...prevAuth,
        user: parsedData.user,
        token: parsedData.token,
      }));
    }
  }, []);

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

// coustom hook
const useAuth = () => useContext(AuthContext);
export { useAuth, AuthProvider };
