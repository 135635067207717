import React from "react";
import styled from "styled-components";

const Image = styled.div`
  width: 100%;
  height: 100%;
  background: url(${(props) => props.$img});
  background-size: cover;
  background-size: ${(props) =>
    props.backgroundSize ? props.backgroundSize : "contain"};
  background-position: center center;
  background-repeat: no-repeat;
`;

const Slide = (props) => {
  return (
    <div
      className="slide"
      //  style={{ backgroundColor: `#${item.bg}` }}
    >
      <div className="image-container">
        {/* <img src={item.img} alt="nothing" /> */}
        <Image $img={props.img} backgroundSize={props.backgroundSize} />
      </div>
    </div>
  );
};

export default Slide;
