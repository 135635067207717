import React, { useState } from "react";
import "./Products.scss";
import Product from "../Product/Product";
import Loading from "../Loading/Loading";
const Products = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="products_container">
      <h1>{props.title}</h1>
      <div className="products_wrapper">
        {props.ProductItems?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {loading ? (
                <Loading />
              ) : (
                <Product
                  key={index}
                  id={item._id}
                  img={`${process.env.REACT_APP_API}/api/v1/products/product-image/${item._id}`}
                  title={item.name}
                  price={item.price}
                  desc={item.description}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Products;
