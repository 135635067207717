import React from "react";
import "./categoryform.scss";
const CategoryForm = ({ handleSubmit, value, setValue, btnName }) => {
  return (
    <>
      <form className="categoryForm" onSubmit={handleSubmit}>
        <input
          type="text"
          minLength={3}
          placeholder="Enter New Category"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required
        />
        <button type="submit">{btnName}</button>
      </form>
    </>
  );
};

export default CategoryForm;
