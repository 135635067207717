import React from "react";
import { NavLink } from "react-router-dom";
import "./adminMenu.scss";

const AdminMenu = () => {
  return (
    <>
      <div className="adminMenu">
        <NavLink to="/dashboard/admin" className="panel-title">
          Admin Panel
        </NavLink>
        <ul>
          <li>
            <NavLink to="/dashboard/admin/create-category">
              Create Category
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/admin/create-products">
              Create Products
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/admin/create-slider-image">
              Create Slider Images
            </NavLink>
          </li>

          <li>
            <NavLink to="/dashboard/admin/slider-images">Slider Images </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/admin/products">Products</NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/admin/users">Users</NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AdminMenu;
