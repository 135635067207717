import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import AdminMenu from "../../Components/Layout/AdminMenu";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-hot-toast";
const Image = styled.div`
  width: 100%;
  height: 100%;
  background: url(${(props) => props.$img});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
`;
const SliderImages = () => {
  const [sliderProducts, setSliderProducts] = useState([]);
  const getAllSliderProducts = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/slider/get-slider-products`
      );
      if (data?.success) {
        setSliderProducts(data.SliderProduct);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };
  useEffect(() => {
    getAllSliderProducts();
  }, []);
  return (
    <Layout>
      <div className="adminDashboard">
        <AdminMenu />
        <div className="adminDashboard__content">
          <div className="adminProduct">
            <h1 className="center">All Slider Image List</h1>
            <div className="adminProduct_list">
              {sliderProducts.map((p) => {
                return (
                  <Link
                    to={`/dashboard/admin/slider-images/${p.slug}`}
                    key={p._id}
                    className="product-link"
                  >
                    <div className="product_container">
                      <div className="card">
                        <div className="inner-card">
                          <div className="front">
                            <div className="product_img">
                              <Image
                                $img={`${process.env.REACT_APP_API}/api/v1/slider/slider-product-image/${p._id}`}
                              />
                            </div>
                            <div className="product_info">
                              <p className="title">{p.name}</p>
                              {/* <p className='desc'>{props.desc}</p> */}
                              {/* <div className='order'>
        
                             <p className='price'>{props.price}</p>
                                        <Button >Buy Now</Button>
                                    </div> */}
                            </div>
                          </div>
                          <div className="back">
                            <h1>{p.name}</h1>
                            <span className="desc">{p.description}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SliderImages;
