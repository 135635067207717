import React from "react";
import Layout from "../../Components/Layout/Layout";
import AdminMenu from "../../Components/Layout/AdminMenu";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";

const CreateSliderImage = () => {
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("auth"));

  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      // if you dont want to use form data then wrap all input and select tag tags with in form tag and add handle create function on form onsubmit event
      const productData = new FormData();
      productData.append("name", name);
      productData.append("image", image);
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/slider/create-slider-product`,
        productData,
        {
          headers: {
            authorization: `${auth.token}`,
          },
        }
      );
      if (data?.success) {
        toast.success(data.message);
        navigate("/dashboard/admin/slider-images");
        setName("");
        setImage("");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <Layout title={"Create Products - NanduChef"}>
      <div className="adminDashboard">
        <AdminMenu />
        <div className="adminDashboard__content">
          <h1>Create Slider Products</h1>
          <div className="product-inputs">
            <input
              type="text"
              value={name}
              className="form-control"
              placeholder="Write a name"
              onChange={(e) => setName(e.target.value)}
            />
            <div className="mb-3 productupload">
              <label className="btn mb-2">
                {image ? image.name : "Upload Image"}
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={(e) => setImage(e.target.files[0])}
                  hidden
                />
              </label>
            </div>
            <div className="mb-2">
              {image && (
                <div className="text-center image-preview">
                  <img src={URL.createObjectURL(image)} alt="product_image" />
                </div>
              )}
            </div>

            <button className="btn" onClick={handleCreate}>
              Create Slider Product
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateSliderImage;
