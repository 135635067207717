import React, { useState, useEffect } from "react";
import Layout from "../Components/Layout/Layout";
import "./category.scss";
import axios from "axios";
import Products from "../Components/Products/Products";
import Loading from "../Components/Loading/Loading";

const CategoryPage = (props) => {
  const [products, setProducts] = useState([]);
  const [desiredCategory, setDesiredCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [displayCount, setDisplayCount] = useState(0); // Initial display count
  const increment = 4; // Number of items to load on each "Load More" click

  // get products by category
  const getProductsByCategory = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/products/get-products`
      );
      let filterProducts = data.products;
      let filteredProducts = filterProducts.filter(
        (item) => item.category.name === desiredCategory
      );
      // Slice the products array to display only a portion of it
      const slicedProducts = filteredProducts.slice(0, displayCount);
      setProducts(filteredProducts);
      // setProducts(slicedProducts);
      // setFilteredProducts(filteredProducts); // Update the state variable
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // capitalize first letter of every word
  const capitalizeFirstLetter = (string) => {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleLoadMore = () => {
    setDisplayCount(displayCount + increment);
  };

  useEffect(() => {
    getProductsByCategory();
    setDesiredCategory(props.title);
    console.log(products);
  }, [displayCount, desiredCategory, props.title]);

  return (
    <Layout title={`${capitalizeFirstLetter(props.title)} | NanduChef`}>
      <div className="category_container">
        {loading ? (
          <Loading />
        ) : (
          <Products title={props.title} ProductItems={products} />
        )}
      </div>
      {products.length < filteredProducts.length && (
        <div className="m-2 p-3 loadmorecontainer">
          <button className="btn loadmore" onClick={handleLoadMore}>
            Load More
          </button>
        </div>
      )}
    </Layout>
  );
};

export default CategoryPage;
