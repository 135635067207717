import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import AdminMenu from "../../Components/Layout/AdminMenu";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const UpdateSliderImage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [id, setId] = useState("");
  const auth = JSON.parse(localStorage.getItem("auth"));

  // get single product
  const getSingleProduct = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/slider/get-slider-product/${params.slug}`
      );
      console.log(data);
      setName(data.sliderProduct.name);
      setId(data.sliderProduct._id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProduct();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      // if you dont want to use form data then wrap all input and select tag tags with in form tag and add handle create function on form onsubmit event
      const productData = new FormData();
      productData.append("name", name);
      productData.append("image", image);
      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/slider/update-slider-product/${id}`,
        productData,
        {
          headers: {
            authorization: `${auth.token}`,
          },
        }
      );
      if (data?.success) {
        toast.success(data.message);
        navigate("/dashboard/admin/slider-images");
        setName("");
        setImage("");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleDelete = async (e) => {
    try {
      let answer = window.confirm(
        "Are you sure you want to delete this product?"
      );
      if (!answer) return;
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/slider/delete-slider-product/${id}`,

        {
          headers: {
            authorization: `${auth.token}`,
          },
        }
      );
      console.log(data);
      if (data?.success) {
        toast.success(data.message);
        navigate("/dashboard/admin/slider-images");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <Layout title={"Create Products - NanduChef"}>
      <div className="adminDashboard">
        <AdminMenu />
        <div className="adminDashboard__content">
          <h1>Update Products</h1>
          <div className="product-inputs">
            <input
              type="text"
              value={name}
              className="form-control"
              placeholder="Write a name"
              onChange={(e) => setName(e.target.value)}
            />

            <div className="mb-3 productupload">
              <label className="btn mb-2">
                {image ? image.name : "Upload Image"}
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={(e) => setImage(e.target.files[0])}
                  hidden
                />
              </label>
            </div>
            <div className="mb-2">
              {image ? (
                <div className="text-center image-preview">
                  <img src={URL.createObjectURL(image)} alt="product_image" />
                </div>
              ) : (
                <div className="text-center image-preview">
                  <img
                    src={`${process.env.REACT_APP_API}/api/v1/slider/slider-product-image/${id}`}
                    alt="product_image"
                  />
                </div>
              )}
            </div>

            <button className="btn mb-2" onClick={handleUpdate}>
              Update Slider Image
            </button>
            <button className="btn danger" onClick={handleDelete}>
              Delete Slider Image
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UpdateSliderImage;
