import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import AdminMenu from "../../Components/Layout/AdminMenu";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Select } from "antd";
const { Option } = Select;
const UpdateUsers = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [id, setId] = useState("");

  const auth = JSON.parse(localStorage.getItem("auth"));

  // get single user
  const getSingleUser = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/users/get-user/${params.slug}`
      );
      console.log(data);

      setName(data.user.name);
      setEmail(data.user.email);
      // setRole(data.user.isAdmin);
      setRole(data.user.isAdmin ? "admin" : "user");
      setId(data.user._id);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getSingleUser();
  }, []);

  useEffect(() => {
    console.log("name", name);
    console.log("email", email);
    console.log("role", role);
  }, [name, email, role]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      if (id === "6522565fa3a48b13b54d0bdc") {
        // You can display a message or take any other action here
        toast.error("Cannot change the role for this specific user.");
        return;
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/users/update-user/${id}`,
        {
          name,
          email,
          isAdmin: role === "admin",
        },
        {
          headers: {
            authorization: `${auth.token}`,
          },
        }
      );
      if (data?.success) {
        toast.success(data.message);
        navigate("/dashboard/admin/users");
        setName("");
        setEmail("");
        setRole("");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleDelete = async () => {
    try {
      if (id === "6522565fa3a48b13b54d0bdc") {
        // You can display a message or take any other action here
        toast.error("Cannot delete super Admin user.");
        return;
      }
      let answer = window.confirm("Are you sure you want to delete this user?");
      if (!answer) return;
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/users/delete-user/${id}`,
        {
          headers: {
            authorization: `${auth.token}`,
          },
        }
      );
      // console.log(data);
      if (data.success) {
        toast.success(data.message);
        navigate("/dashboard/admin/users");
      } else {
        toast.error(data.message);
        console.log(data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Cannot Delete last Admin User");
    }
  };
  return (
    <Layout title={"Update Users - NanduChef"}>
      <div className="adminDashboard">
        <AdminMenu />
        <div className="adminDashboard__content">
          <h1>Update User</h1>
          <div className="product-inputs">
            <form onSubmit={handleUpdate} className="product-inputs">
              <input
                type="text"
                value={name}
                className="form-control"
                placeholder="Username"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <input
                type="text"
                value={email}
                className="form-control"
                placeholder="User Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />

              <Select
                bordered={true}
                placeholder="Select Role"
                onChange={(value) => {
                  setRole(value);
                }}
                size="large"
                showSearch
                value={role}
              >
                <Option value="user">User</Option>
                <Option value="admin">Admin</Option>
              </Select>

              <button type="submit" className="btn mb-2">
                Update User
              </button>
            </form>
            <button className="btn danger" onClick={handleDelete}>
              Delete User
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UpdateUsers;
