import "./App.scss";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Pagenotfound from "./pages/Pagenotfound";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import { Toaster } from "react-hot-toast";
import Dashboard from "./pages/user/Dashboard";
import { PrivateRoute } from "./Components/Routes/Private";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import { AdminRoute } from "./Components/Routes/AdminRoute";
import CreateProducts from "./pages/Admin/CreateProducts";
import CreateCategory from "./pages/Admin/CreateCategory";
import Users from "./pages/Admin/Users";
import Products from "./pages/Admin/Products";
import UpdateProducts from "./pages/Admin/UpdateProducts";
import SearchPage from "./pages/Search/SearchPage";
import useCategory from "./hooks/useCategory";
import CategoryPage from "./pages/CategoryPage";
import FindStore from "./pages/FindStore/FindStore";
import CreateSliderImage from "./pages/Admin/CreateSliderImage";
import SliderImages from "./pages/Admin/SliderImages";
import UpdateSliderImage from "./pages/Admin/UpdateSliderImage";
import UpdateUsers from "./pages/Admin/UpdateUsers";
function App() {
  const categories = useCategory();
  return (
    <>
      <Toaster position="top-center" />
      <Routes>
        {categories.map((category, i) => (
          <Route
            key={i}
            path={`/category/${category.slug}`}
            element={<CategoryPage title={category.name} />}
          />
        ))}

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route path="user" element={<Dashboard />} />
        </Route>
        <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="admin/create-category" element={<CreateCategory />} />
          <Route path="admin/create-products" element={<CreateProducts />} />
          <Route
            path="admin/create-slider-image"
            element={<CreateSliderImage />}
          />
          <Route path="admin/product/:slug" element={<UpdateProducts />} />
          <Route path="admin/products" element={<Products />} />

          <Route
            path="admin/slider-products/:slug"
            element={<UpdateSliderImage />}
          />
          <Route path="admin/slider-images" element={<SliderImages />} />
          <Route
            path="admin/slider-images/:slug"
            element={<UpdateSliderImage />}
          />
          <Route path="admin/users" element={<Users />} />
          <Route path="admin/users/:slug" element={<UpdateUsers />} />
        </Route>
        <Route path="/" element={<HomePage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/findStore" element={<FindStore />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/*" element={<Pagenotfound />} />
      </Routes>
    </>
  );
}

export default App;
