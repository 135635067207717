import React from "react";
import Layout from "../../Components/Layout/Layout";
import { useSearch } from "../../context/Search";
import Products from "../../Components/Products/Products";
// import "../../../scss/_variables.scss";
const SearchPage = () => {
  const [values, setValues] = useSearch();

  const h1Style = {
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: `#937f66`,
    fontSize: "2.5rem",
    fontWeight: "600",
  };

  return (
    <Layout title={"Search results"}>
      <div className="container">
        <div className="center">
          {values?.results.length < 1 ? (
            <h1 style={h1Style}>No Product Found</h1>
          ) : (
            <Products title="Search Result" ProductItems={values?.results} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SearchPage;
