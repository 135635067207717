import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import AdminMenu from "../../Components/Layout/AdminMenu";
import toast from "react-hot-toast";
import axios from "axios";
import "./admin.scss";
import CategoryForm from "../../Components/Form/CategoryForm";

const CreateCategory = () => {
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [updatedName, setUpdatedName] = useState("");
  function toCapitalCase(str) {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  }
  // get token from localStorage
  const auth = JSON.parse(localStorage.getItem("auth"));

  // Create category handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const auth = JSON.parse(localStorage.getItem("auth"));
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/category/create-category`,
        { name: name.toLocaleLowerCase() },
        {
          headers: {
            Authorization: `${auth.token}`,
          },
        }
      );
      if (data.success) {
        toast.success(`${toCapitalCase(name)} ${data.message}`);
        setName("");
        getAllCategory();
      } else {
        toast.error(data.message);
      }
    } catch (error) {

      toast.error(error.message);
    }
  };

  // Edit category handle form submit
  const handleUpdateCategory = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/category/update-category/${selected._id}`,
        { name: updatedName },
        {
          headers: {
            authorization: `${auth.token}`,
          },
        }
      );
      if (res.data.success) {
        toast.success(`${toCapitalCase(updatedName)} ${res.data.message}`);
        setSelected(null);
        setUpdatedName("");
        setVisible(false);
        getAllCategory();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Error updating category");
    }
  };

  // Delete category handle form submit

  const handleDeleteCategory = async (pId) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/category/delete-category/${pId}`,

        {
          headers: {
            authorization: `${auth.token}`,
          },
        }
      );
      if (res.data.success) {
        toast.success(` ${res.data.message}`);
        getAllCategory();
      }
    } catch (error) {
      toast.error(error.message);
  
    }
  };

  // Get all categories
  const getAllCategory = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/category/get-category`
      );
      if (res.data.success) {
        setCategories(res.data.category);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <Layout title={"Dashboard - Create Category"}>
      <div className="adminDashboard">
        <AdminMenu />
        <div className="adminDashboard__content">
          <h1>Manage Category</h1>
          <CategoryForm
            handleSubmit={handleSubmit}
            value={name}
            setValue={setName}
            btnName={"Create"}
          />
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Category Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {categories.length === 0 ? (
                <tbody className="center">
                  <tr>
                    <td>No categories available.</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {categories?.map((category, i) => (
                    <tr key={i}>
                      <td>{category.name}</td>
                      <td>
                        <button
                          onClick={() => {
                            setVisible(true);
                            setUpdatedName(category.name);
                            setSelected(category);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => {
                            handleDeleteCategory(category._id);
                          }}
                        >
                          delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <div className="modal" style={{ display: visible ? "flex" : "none" }}>
            <div className="modal-content">
              <span className="modal-close" onClick={() => setVisible(false)}>
                &times;
              </span>
              <h1>Edit Category</h1>
              <CategoryForm
                value={updatedName}
                setValue={setUpdatedName}
                handleSubmit={handleUpdateCategory}
                btnName={"Update"}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateCategory;
