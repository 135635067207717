import React from "react";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

import Header from "./Header";
const Layout = (props) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <meta name="author" content={props.author} />
        <title>{props.title}</title>
      </Helmet>
      <Header />
      <main>{props.children}</main>
      <Footer />
    </>
  );
};

Layout.defaultProps = {
  title: "NanduChef",
  description:
    "NanduChef is a food brand that takes culinary delight to a new level. With an unwavering commitment to exceptional taste, quality ingredients and innovative creations, NanduChef captures the essence of culinary art in every gourmet offering.",
  keywords:
    "NanduChef, NanduChef.com, NanduChef.in, NanduChef.co.in, NanduChef.co, NanduChef.co.uk, NanduChef.co.us, NanduChef.co.au, NanduChef.co.ca, NanduChef.co.nz, NanduChef.co.,pklivebakery, pkbakery",
  author: "NanduChef",
};

export default Layout;
