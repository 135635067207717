import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import "./search.scss";
import axios from "axios";
import { useSearch } from "../../context/Search";
const SearchForm = () => {
  const navigate = useNavigate();
  const [values, setValues] = useSearch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/products/search/${values.keyword}`
      );
      setValues({ ...values, results: data });
      navigate("/search");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    // <div className="d-center">
    <form className="search_box" onSubmit={handleSubmit}>
      <input
        type="search"
        value={values.keyword}
        onChange={(e) => setValues({ ...values, keyword: e.target.value })}
        placeholder="Search"
      />
      <button type="submit">
        <Search className="icon" />
      </button>
    </form>
    // </div>
  );
};

export default SearchForm;
