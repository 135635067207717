import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import AdminMenu from "../../Components/Layout/AdminMenu";
import { useAuth } from "../../context/auth";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
const Users = () => {
  const [auth] = useAuth();
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  // get all users
  const getAllUsers = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/users/all-users`
      );
      setId(data.users._id);
      setUsers(data.users);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDelete = async (uId) => {
    if (users.length <= 1) return toast.error("Can't delete last user");
    try {
      let answer = window.confirm("Are you sure you want to delete this user?");
      if (!answer) return;
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/users/delete-user/${uId}`,
        {
          headers: {
            authorization: `${auth.token}`,
          },
        }
      );
      if (data.success) {
        toast.success(data.message);
        getAllUsers();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Cannot Delete last Admin User");
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);
  return (
    <Layout title={"Dashboard - All Users"}>
      <div className="adminDashboard">
        <AdminMenu />
        <div className="adminDashboard__content">
          <h1>All Users</h1>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>User Email</th>

                  <th>Role</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {users?.map((user, i) => (
                <tbody key={i}>
                  <tr>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.isAdmin === true ? "Admin" : "User"}</td>
                    <td>
                      <button
                        onClick={() => {
                          navigate(`/dashboard/admin/users/${user._id}`);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => {
                          handleDelete(user._id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Users;
