import { useState, useEffect } from "react";
import axios from "axios";

export default function useProducts() {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);
  // get categories
  const getProducts = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/products/get-products`
      );
      setProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };

  return products;
}
