import React from "react";
import Layout from "../../Components/Layout/Layout";
import AdminMenu from "../../Components/Layout/AdminMenu";
import "./AdminDashboard.scss";
import { useAuth } from "../../context/auth";

const AdminDashboard = () => {
  const [auth] = useAuth();
  return (
    <Layout title={"Admin Dashboard - NanduChef"}>
      <div className="adminDashboard">
        <AdminMenu />
        <div className="adminDashboard__content">
          <h1>Admin Dashboard</h1>
          <h2>Welcome {auth.user.name}</h2>
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashboard;
