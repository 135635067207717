import CircularProgress from "@mui/material/CircularProgress";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Spinner.scss";
import { useAuth } from "../../context/auth";

const Spinner = ({ path = "login" }) => {
  const [count, setCount] = useState(3);
  const navigate = useNavigate();
  const location = useLocation();
  const [auth, setAuth] = useAuth();
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevValue) => --prevValue);
    }, 1000);
    count === 0 && navigate(`${path}`, { state: location.pathname });

    return () => clearInterval(interval);
  }, [count, navigate, location, path]);
  return (
    <>
      <div className="spinner_container">
        <h1>Redirecting you in {count} seconds</h1>
        <CircularProgress color="error" />;
      </div>
    </>
  );
};

export default Spinner;
